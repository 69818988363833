@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap');
.homeContainer {
    display: flex;
    /* height: 100vh; */
    flex-direction: column;
    font-family: 'Readex Pro', sans-serif;
    background-color: #fff;
    width:100vw;
    
}

.hero {
    height: 80vh;
     background-image: url("./duckbg.png");
     width: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #1D1034;
    display: flex;
}

.svgcont {
    flex: 1;
    background: #fbfbfd;
    border-radius: 12px;
    width: auto;
    margin: 50px;
   
}

.intro {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
    width: auto;
    background-image: url("./dmdpng.png") ;
    background-color: black;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
    /* background: yellow; */
}
.intro img{
    margin-top: 20vh;
}
.wolf {
    padding-top: 20vh;
    margin-top: 60px;
    margin-bottom: 60px;
    
}
.intro img {
    max-width: 20vw;
    max-height: 20vw;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.container {
    text-align: center;
    font-size: 35px;
    padding: 6px;
    font-weight: 600;
    background: linear-gradient(319deg, #e76d09 0%, #ff6600 37%, #f53e1d 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.heroimg {
    
    height: 550px;
    width: 650px;
    border-radius: 12px;
    justify-content: center;
}

.herotext {
    flex: 1;
    margin: 3rem 3rem auto;
    background: rgba(0, 0, 0, 0.81);
    padding: 15px;
    border-radius: 12px;
    height: auto;
    
    justify-content: center;
    align-items: center;
    text-align: center;
}

.button {
    display: flex;
    height: 50px;
    width: 180px;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: linear-gradient(319deg, #e76d09 0%, #ff6600 37%, #f53e1d 100%);
    text-decoration: none;
}

.text {
    font-size: 20px;
    text-align: left;
    padding-top: 50px;
    padding-bottom: 50px;
    font-weight: 500;
    color: #949494;
}

.h1 {
    text-align: center;
    padding: 10px;
    margin-top: 60px;
    font-weight: 600;
    font-size: 10vw;
    width: auto;
    /* background: -webkit-linear-gradient(0.25turn, #673ab7, #cc00ff, #333); background-color: #663dff; */
    background-image: linear-gradient(319deg, #e76d09 0%, #ff6600 37%, #f53e1d 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.h2 {
    text-align: left;
    font-size: 35px;
    padding-top: 30px;
    font-weight: 600;
    /* background-color: #d63230; */
    background: -webkit-linear-gradient(319deg, #d63230 0%, #f39237 37%, #97cc04 100%);
    /* background: -webkit-linear-gradient(#ff5722, #ff6699, #333); */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.h3 {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #fff;
}

.carousel {
    height: 100vh;
    background: linear-gradient(319deg, #e76d09 0%, #ff6600 37%, #f53e1d 100%);
    border-radius: 12px;
    margin: 30px;
}

.caroimg {
    width: 20%;
    height: 100%;
    border-radius: 22px;
    padding: 10px;
    margin: 25px;
}

.image-list {
    align-items: center;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.link {
    height: 100px;
    background: #fbfbfd;
    display: flex;
    flex-direction: row;
    padding: 20px;
    align-items: center;
    justify-content: center;
}

.linki {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stake {
    display: flex;
    height: 50px;
    width: 180px;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: linear-gradient(319deg, #e76d09 0%, #ff6600 37%, #f53e1d 100%);
    color: #fff;
    text-decoration: none;
    font-size: 25px;
    font-weight: 500;
}

.mint {
    text-decoration: none;
    display: flex;
    height: 50px;
    width: 180px;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: linear-gradient(319deg, #e76d09 0%, #ff6600 37%, #f53e1d 100%);
    color: #fff;
    font-size: 25px;
    font-weight: 500;
    margin-left: 10px;
}

.footer {
    background: #191919;
    height: 5vh;
    color: #fff;
    font-weight: 600;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    /* linear-gradient(to bottom, #ff5722 0%, #ff6699 100%); */
}

.love {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    margin-right: 5px;
}