@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
:root {
    --color1: #060099;
    --color2: #C51E52;
    --color3: #012515;
    --color4: #427a8b;
    --color5: #8E9AAF;
    --color6: linear-gradient(to bottom, #535353 0%, #535353 100%);
    --color7: linear-gradient(to bottom, #191919 0%, #161616 100%);
    --white: #FFFFFF;
    --black: #000000;
    --font1: 'Motserrat', sans-serif;
    --font2: 'Poppins', sans-serif;
}
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 116, 2, 0.795);
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(255, 255, 255);
  }



  
@media all and (min-width: 0px) {
    
    .stake-app {
        background-image: url('./bg1.jpg') ;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 100%;
        width: 100vw;
    }
    .stake-app2 {
        background: linear-gradient(to bottom, #191919 0%, #757575 50%, #dd6605 100%);
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .stake-app3 {
        background: linear-gradient(to bottom, #191919 0%, #757575 50%, #dd6605 100%);
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .stake-app4 {
        background: linear-gradient(to bottom, #191919 0%, #191919 50%, #191919 100%);
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
     .connect-btn{
        background: var(--white);
        font: 1.125rem/1.6rem var(--font1);
        color: var(--color1);
        border-radius: 12px;
        border: none;
        height: 50px;
        width: 136px;
        margin-bottom: 2rem;
    } 
    .stake-app h1 {
        font: 2.5rem/4rem var(--font1);
        color: var(--white);
        font-weight: bold;
    }
    .stake-app h2 {
        font: 1.125rem/1.8rem var(--font2);
        color: var(--white);
        font-weight: bold;
        margin-bottom: 2rem;
    }
    .card-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        max-width: 90%;
        width: 550px;
        height: 550px;
        margin: 0 auto;
        /* border: 3px solid var(--white); */
        border-radius: 20px;
        transition: 0.5s all;
        background: var(--color6);
        margin-bottom: 10rem;
    }
    .card-container2 {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        max-width: 90%;
        width: 550px;
        height: 550px;
        margin: 0 auto;
        /* border: 3px solid var(--white); */
        border-radius: 20px;
        transition: 0.5s all;
        
        background-size: contain;
        justify-content: center;
        margin-bottom: 10rem;
    }

    .btn {
        background: var(--color7);
        color: var(--white);
        font: 1rem/1rem var(--font1);
        border-radius: 12px;
        font-weight: bold;
        outline: none;
        border: none;
        height: 56px;
        width: 100%;
    }
    .mb-5 {
        margin: 1rem 0;
    }
    .form {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .form input {
        height: 56px;
        max-width: 40%;
        width: 50%;
        outline: none;
        border: none;
        border-radius: 12px 0 0 12px;
        font: 1.125rem/1.8rem var(--font2);
        color: var(--black);
        text-align: center;
    }
    .form button {
        border-radius: 0 12px 12px 0;
        height: 56px;
        width: 50%;
        margin: 1rem 0;
    }
    .form input::placeholder,
    .form input:focus {
        padding: 0 0.5rem;
        font: 0.8rem/1rem var(--font2);
        color: var(--black)
    }
    .card-container div div {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin: 0 auto;
    }
    .reward {
        background: var(--color5);
        border: 1px solid var(--white);
        font: 0.8rem/1rem var(--font2);
        font-weight: 700;
        border-radius: 12px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 50px;
        width: 90%;
        margin: 0 auto 5px;
        color: var(--white);
    }
    .reward span {
        padding: 0 0.5rem 0 0;
    }
    .stake-app p {
        margin: 1rem 0 2rem;
        font: 1rem/1.6rem var(--font2);
        color: var(--white);
        font-weight: 500;
    }
    .stake {
        color: #0000;
    }


      .nftcontainer{
        display: flex;
        width: auto;
        align-items: center;
        margin-bottom: 2vw;
        margin-top: -4vw;
        
      }
      .nfts {
        background: rgba(75, 75, 75, 0.6);
        border-radius: 5px;
        
        height: 35vw;
        overflow-y: scroll;
        width: 30vw;
        cursor: pointer;
      }
      .nfts img {
        display: inline-block;
        width: 20vw;
        height: 20vw;
        margin: 10px auto;
        border-radius: 1.2rem;
        box-shadow: 1px 1px 2px 1px rgba(31, 31, 31, 0.5);
      }
      .nfts span {
        display: block;
        width: 100%;
        background: rgba(20, 20, 20, 0);
        border: 1px solid white;
        text-align: center;
        margin-bottom: 10px;
        justify-content: center;
        color:rgb(228, 228, 228)
      }
      

}

@media all and (min-width: 768px) {
    .form input {
        width: 55%;
    }
    .form button {
        width: 50%;
    }
}

.top-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #00BAF0;
    background: linear-gradient(to left, #fd643a, #ff6600);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #FFF;
    height: 50px;
    padding: 1em;
    text-decoration: none;
  }
  
  .menu {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    text-decoration: none !important;
    margin: 0;
    padding: 0;
  }
  
  .menu li {
    margin: 0 1rem;
    overflow: hidden;
    text-decoration: none !important;
    
    
  }
  
  .menu-button-container {
    display: none;
    height: 100%;
    width: 30px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  #menu-toggle {
    display: none;
  }
  
  .menu-button,
  .menu-button::before,
  .menu-button::after {
    display: block;
    background-color: #fff;
    position: absolute;
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
  }
  
  .menu-button::before {
    content: '';
    margin-top: -8px;
  }
  
  .menu-button::after {
    content: '';
    margin-top: 8px;
  }
  
  #menu-toggle:checked + .menu-button-container .menu-button::before {
    margin-top: 0px;
    transform: rotate(405deg);
  }
  
  #menu-toggle:checked + .menu-button-container .menu-button {
    background: rgba(255, 255, 255, 0);
  }
  
  #menu-toggle:checked + .menu-button-container .menu-button::after {
    margin-top: 0px;
    transform: rotate(-405deg);
  }
  
  @media (max-width: 700px) {
    .menu-button-container {
      display: flex;
    }
    .menu {
      position: absolute;
      top: 0;
      margin-top: 50px;
      left: 0;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
    #menu-toggle ~ .menu li {
      height: 0;
      margin: 0;
      padding: 0;
      border: 0;
      transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    #menu-toggle:checked ~ .menu li {
      border: 1px solid #333;
      height: 2.5em;
      padding: 0.5em;
      transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    .menu > li {
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0.5em 0;
      width: 100%;
      color: white;
      background-color: #222;
      text-decoration: none;
    }
    .menu > li:not(:last-child) {
      border-bottom: 1px solid #444;
    }}