@import "./stake.css";
@media all and (min-width: 0px) {
    .nav-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: #00BAF0;
        background: linear-gradient(to left, #fa4614, #f89b19);
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        color: #FFF;
        height: 50px;
        padding: 1em;
        
        width: 100vw;

    }
    .nav-wrap ul {
        display: flex;
        justify-content: center;
        text-decoration: none;

        
    }
    .nav-wrap ul li {
        margin: 0 1rem;
        display: block;
        text-align: center;
        text-decoration: none;
        background: linear-gradient(to bottom, #010101 0%, #010101 100%);
        border-radius: 20px;
        
    }
    .nav-wrap ul li a {
        color: var(--white);
        font: 1rem/4rem var(--font1);
        text-decoration: none;
        background: linear-gradient(to bottom, #010101 0%, #010101 100%);
        border-radius: 20px;

    }
    .mintbox {
        background: linear-gradient(to bottom, #ff5722 0%, #ff6699 100%);
        border-radius: 20px;
        width: 100px;
        text-align: center;
    }
    .stakebox {
        background: linear-gradient(to bottom, #010101 0%, #010101 100%);
        border-radius: 20px;
        width: 150px;
        text-align: center;
        
    }
    .logo {
        /* background: white; */
        width: 63px;
        height: 60px;
        
    }
}

@media all and (min-width: 768px) {}