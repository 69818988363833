@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap);
:root {
    --color1: #060099;
    --color2: #C51E52;
    --color3: #012515;
    --color4: #427a8b;
    --color5: #8E9AAF;
    --color6: linear-gradient(to bottom, #535353 0%, #535353 100%);
    --color7: linear-gradient(to bottom, #191919 0%, #161616 100%);
    --white: #FFFFFF;
    --black: #000000;
    --font1: 'Motserrat', sans-serif;
    --font2: 'Poppins', sans-serif;
}
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 116, 2, 0.795);
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(255, 255, 255);
  }



  
@media all and (min-width: 0px) {
    
    .stake-app {
        background-image: url(/static/media/bg1.995c6b82.jpg);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 100%;
        width: 100vw;
    }
    .stake-app2 {
        background: linear-gradient(to bottom, #191919 0%, #757575 50%, #dd6605 100%);
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .stake-app3 {
        background: linear-gradient(to bottom, #191919 0%, #757575 50%, #dd6605 100%);
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .stake-app4 {
        background: linear-gradient(to bottom, #191919 0%, #191919 50%, #191919 100%);
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
     .connect-btn{
        background: #FFFFFF;
        background: var(--white);
        font: 1.125rem/1.6rem 'Motserrat', sans-serif;
        font: 1.125rem/1.6rem var(--font1);
        color: #060099;
        color: var(--color1);
        border-radius: 12px;
        border: none;
        height: 50px;
        width: 136px;
        margin-bottom: 2rem;
    } 
    .stake-app h1 {
        font: 2.5rem/4rem 'Motserrat', sans-serif;
        font: 2.5rem/4rem var(--font1);
        color: #FFFFFF;
        color: var(--white);
        font-weight: bold;
    }
    .stake-app h2 {
        font: 1.125rem/1.8rem 'Poppins', sans-serif;
        font: 1.125rem/1.8rem var(--font2);
        color: #FFFFFF;
        color: var(--white);
        font-weight: bold;
        margin-bottom: 2rem;
    }
    .card-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        max-width: 90%;
        width: 550px;
        height: 550px;
        margin: 0 auto;
        /* border: 3px solid var(--white); */
        border-radius: 20px;
        transition: 0.5s all;
        background: linear-gradient(to bottom, #535353 0%, #535353 100%);
        background: var(--color6);
        margin-bottom: 10rem;
    }
    .card-container2 {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        max-width: 90%;
        width: 550px;
        height: 550px;
        margin: 0 auto;
        /* border: 3px solid var(--white); */
        border-radius: 20px;
        transition: 0.5s all;
        
        background-size: contain;
        justify-content: center;
        margin-bottom: 10rem;
    }

    .btn {
        background: linear-gradient(to bottom, #191919 0%, #161616 100%);
        background: var(--color7);
        color: #FFFFFF;
        color: var(--white);
        font: 1rem/1rem 'Motserrat', sans-serif;
        font: 1rem/1rem var(--font1);
        border-radius: 12px;
        font-weight: bold;
        outline: none;
        border: none;
        height: 56px;
        width: 100%;
    }
    .mb-5 {
        margin: 1rem 0;
    }
    .form {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .form input {
        height: 56px;
        max-width: 40%;
        width: 50%;
        outline: none;
        border: none;
        border-radius: 12px 0 0 12px;
        font: 1.125rem/1.8rem 'Poppins', sans-serif;
        font: 1.125rem/1.8rem var(--font2);
        color: #000000;
        color: var(--black);
        text-align: center;
    }
    .form button {
        border-radius: 0 12px 12px 0;
        height: 56px;
        width: 50%;
        margin: 1rem 0;
    }
    .form input::-webkit-input-placeholder {
        padding: 0 0.5rem;
        font: 0.8rem/1rem 'Poppins', sans-serif;
        font: 0.8rem/1rem var(--font2);
        color: #000000;
        color: var(--black)
    }
    .form input:-ms-input-placeholder {
        padding: 0 0.5rem;
        font: 0.8rem/1rem 'Poppins', sans-serif;
        font: 0.8rem/1rem var(--font2);
        color: #000000;
        color: var(--black)
    }
    .form input::placeholder,
    .form input:focus {
        padding: 0 0.5rem;
        font: 0.8rem/1rem 'Poppins', sans-serif;
        font: 0.8rem/1rem var(--font2);
        color: #000000;
        color: var(--black)
    }
    .card-container div div {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin: 0 auto;
    }
    .reward {
        background: #8E9AAF;
        background: var(--color5);
        border: 1px solid #FFFFFF;
        border: 1px solid var(--white);
        font: 0.8rem/1rem 'Poppins', sans-serif;
        font: 0.8rem/1rem var(--font2);
        font-weight: 700;
        border-radius: 12px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 50px;
        width: 90%;
        margin: 0 auto 5px;
        color: #FFFFFF;
        color: var(--white);
    }
    .reward span {
        padding: 0 0.5rem 0 0;
    }
    .stake-app p {
        margin: 1rem 0 2rem;
        font: 1rem/1.6rem 'Poppins', sans-serif;
        font: 1rem/1.6rem var(--font2);
        color: #FFFFFF;
        color: var(--white);
        font-weight: 500;
    }
    .stake {
        color: #0000;
    }


      .nftcontainer{
        display: flex;
        width: auto;
        align-items: center;
        margin-bottom: 2vw;
        margin-top: -4vw;
        
      }
      .nfts {
        background: rgba(75, 75, 75, 0.6);
        border-radius: 5px;
        
        height: 35vw;
        overflow-y: scroll;
        width: 30vw;
        cursor: pointer;
      }
      .nfts img {
        display: inline-block;
        width: 20vw;
        height: 20vw;
        margin: 10px auto;
        border-radius: 1.2rem;
        box-shadow: 1px 1px 2px 1px rgba(31, 31, 31, 0.5);
      }
      .nfts span {
        display: block;
        width: 100%;
        background: rgba(20, 20, 20, 0);
        border: 1px solid white;
        text-align: center;
        margin-bottom: 10px;
        justify-content: center;
        color:rgb(228, 228, 228)
      }
      

}

@media all and (min-width: 768px) {
    .form input {
        width: 55%;
    }
    .form button {
        width: 50%;
    }
}

.top-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #00BAF0;
    background: linear-gradient(to left, #fd643a, #ff6600);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #FFF;
    height: 50px;
    padding: 1em;
    text-decoration: none;
  }
  
  .menu {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    text-decoration: none !important;
    margin: 0;
    padding: 0;
  }
  
  .menu li {
    margin: 0 1rem;
    overflow: hidden;
    text-decoration: none !important;
    
    
  }
  
  .menu-button-container {
    display: none;
    height: 100%;
    width: 30px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  #menu-toggle {
    display: none;
  }
  
  .menu-button,
  .menu-button::before,
  .menu-button::after {
    display: block;
    background-color: #fff;
    position: absolute;
    height: 4px;
    width: 30px;
    transition: -webkit-transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
  }
  
  .menu-button::before {
    content: '';
    margin-top: -8px;
  }
  
  .menu-button::after {
    content: '';
    margin-top: 8px;
  }
  
  #menu-toggle:checked + .menu-button-container .menu-button::before {
    margin-top: 0px;
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }
  
  #menu-toggle:checked + .menu-button-container .menu-button {
    background: rgba(255, 255, 255, 0);
  }
  
  #menu-toggle:checked + .menu-button-container .menu-button::after {
    margin-top: 0px;
    -webkit-transform: rotate(-405deg);
            transform: rotate(-405deg);
  }
  
  @media (max-width: 700px) {
    .menu-button-container {
      display: flex;
    }
    .menu {
      position: absolute;
      top: 0;
      margin-top: 50px;
      left: 0;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
    #menu-toggle ~ .menu li {
      height: 0;
      margin: 0;
      padding: 0;
      border: 0;
      transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    #menu-toggle:checked ~ .menu li {
      border: 1px solid #333;
      height: 2.5em;
      padding: 0.5em;
      transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    }
    .menu > li {
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0.5em 0;
      width: 100%;
      color: white;
      background-color: #222;
      text-decoration: none;
    }
    .menu > li:not(:last-child) {
      border-bottom: 1px solid #444;
    }}
@media all and (min-width: 0px) {
    .nav-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: #00BAF0;
        background: linear-gradient(to left, #fa4614, #f89b19);
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        color: #FFF;
        height: 50px;
        padding: 1em;
        
        width: 100vw;

    }
    .nav-wrap ul {
        display: flex;
        justify-content: center;
        text-decoration: none;

        
    }
    .nav-wrap ul li {
        margin: 0 1rem;
        display: block;
        text-align: center;
        text-decoration: none;
        background: linear-gradient(to bottom, #010101 0%, #010101 100%);
        border-radius: 20px;
        
    }
    .nav-wrap ul li a {
        color: var(--white);
        font: 1rem/4rem var(--font1);
        text-decoration: none;
        background: linear-gradient(to bottom, #010101 0%, #010101 100%);
        border-radius: 20px;

    }
    .mintbox {
        background: linear-gradient(to bottom, #ff5722 0%, #ff6699 100%);
        border-radius: 20px;
        width: 100px;
        text-align: center;
    }
    .stakebox {
        background: linear-gradient(to bottom, #010101 0%, #010101 100%);
        border-radius: 20px;
        width: 150px;
        text-align: center;
        
    }
    .logo {
        /* background: white; */
        width: 63px;
        height: 60px;
        
    }
}

@media all and (min-width: 768px) {}
.homeContainer {
    display: flex;
    /* height: 100vh; */
    flex-direction: column;
    font-family: 'Readex Pro', sans-serif;
    background-color: #fff;
    width:100vw;
    
}

.hero {
    height: 80vh;
     background-image: url(/static/media/duckbg.23cf5578.png);
     width: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #1D1034;
    display: flex;
}

.svgcont {
    flex: 1 1;
    background: #fbfbfd;
    border-radius: 12px;
    width: auto;
    margin: 50px;
   
}

.intro {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
    width: auto;
    background-image: url(/static/media/dmdpng.4f599962.png);
    background-color: black;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
    /* background: yellow; */
}
.intro img{
    margin-top: 20vh;
}
.wolf {
    padding-top: 20vh;
    margin-top: 60px;
    margin-bottom: 60px;
    
}
.intro img {
    max-width: 20vw;
    max-height: 20vw;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.container {
    text-align: center;
    font-size: 35px;
    padding: 6px;
    font-weight: 600;
    background: linear-gradient(319deg, #e76d09 0%, #ff6600 37%, #f53e1d 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.heroimg {
    
    height: 550px;
    width: 650px;
    border-radius: 12px;
    justify-content: center;
}

.herotext {
    flex: 1 1;
    margin: 3rem 3rem auto;
    background: rgba(0, 0, 0, 0.81);
    padding: 15px;
    border-radius: 12px;
    height: auto;
    
    justify-content: center;
    align-items: center;
    text-align: center;
}

.button {
    display: flex;
    height: 50px;
    width: 180px;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: linear-gradient(319deg, #e76d09 0%, #ff6600 37%, #f53e1d 100%);
    text-decoration: none;
}

.text {
    font-size: 20px;
    text-align: left;
    padding-top: 50px;
    padding-bottom: 50px;
    font-weight: 500;
    color: #949494;
}

.h1 {
    text-align: center;
    padding: 10px;
    margin-top: 60px;
    font-weight: 600;
    font-size: 10vw;
    width: auto;
    /* background: -webkit-linear-gradient(0.25turn, #673ab7, #cc00ff, #333); background-color: #663dff; */
    background-image: linear-gradient(319deg, #e76d09 0%, #ff6600 37%, #f53e1d 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.h2 {
    text-align: left;
    font-size: 35px;
    padding-top: 30px;
    font-weight: 600;
    /* background-color: #d63230; */
    background: -webkit-linear-gradient(319deg, #d63230 0%, #f39237 37%, #97cc04 100%);
    /* background: -webkit-linear-gradient(#ff5722, #ff6699, #333); */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.h3 {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #fff;
}

.carousel {
    height: 100vh;
    background: linear-gradient(319deg, #e76d09 0%, #ff6600 37%, #f53e1d 100%);
    border-radius: 12px;
    margin: 30px;
}

.caroimg {
    width: 20%;
    height: 100%;
    border-radius: 22px;
    padding: 10px;
    margin: 25px;
}

.image-list {
    align-items: center;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.link {
    height: 100px;
    background: #fbfbfd;
    display: flex;
    flex-direction: row;
    padding: 20px;
    align-items: center;
    justify-content: center;
}

.linki {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stake {
    display: flex;
    height: 50px;
    width: 180px;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: linear-gradient(319deg, #e76d09 0%, #ff6600 37%, #f53e1d 100%);
    color: #fff;
    text-decoration: none;
    font-size: 25px;
    font-weight: 500;
}

.mint {
    text-decoration: none;
    display: flex;
    height: 50px;
    width: 180px;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: linear-gradient(319deg, #e76d09 0%, #ff6600 37%, #f53e1d 100%);
    color: #fff;
    font-size: 25px;
    font-weight: 500;
    margin-left: 10px;
}

.footer {
    background: #191919;
    height: 5vh;
    color: #fff;
    font-weight: 600;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    /* linear-gradient(to bottom, #ff5722 0%, #ff6699 100%); */
}

.love {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    margin-right: 5px;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "coder";
  src: local("coder"), url(/static/media/font.617a0a92.woff) format("woff");
}

body {
  margin: 0;
  font-family: "coder", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width:100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html {
  background-color: "#4C4C4C";
}

div {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

